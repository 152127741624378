import React from "react";
import { Link } from "gatsby";
import { IoBrush, IoDocuments, IoFlash } from "react-icons/io5";

import Image from "../../../components/image";
import { Container, Wrapper, Row, Box } from "../../../components/util";
import {
  CallToAction,
  FeaturePageHeader,
  CountrySpecificContent,
} from "../../../components/site";
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";

const RecruitmentInvoicing = (props) => (
  <Layout>
    <Seo
      title="Invoicing | Karmly Feature"
      description="Fast, accurate client billing is critical to the success of your agency. Keep on top of your cash flow with Karmly’s powerful online invoicing software."
      pathname={props.location.pathname}
    />
    <FeaturePageHeader
      title="Recruitment Billing Made Easy"
      description="Fast, accurate client billing is critical to the success of your agency. Get paid faster and keep on top of your cash flow with Karmly’s powerful and highly configurable online invoicing software."
      image="InvoxyFeature_InvoiceBuilder.png"
    />
    <Container>
      <Wrapper>
        <Row className="-textCenter" stackGap={40}>
          <Box size={33}>
            <h3>
              <IoBrush />
              <br />
              Tailored for Each Client
            </h3>
            <p>
              Save templates for each client to ensure invoices meet their exact
              requirements.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoDocuments />
              <br />
              Fast Bulk Invoicing
            </h3>
            <p>
              Create large batches of invoices from approved timesheets in just
              a few clicks.
            </p>
          </Box>
          <Box size={33}>
            <h3>
              <IoFlash />
              <br />
              Get Paid Faster
            </h3>
            <p>
              Email invoices direct to clients with timesheet and approval
              details attached for quick turnaround.
            </p>
          </Box>
        </Row>
        <hr />
        <CountrySpecificContent forCountry={["New Zealand", "Australia"]}>
          <Row stackGap={60} alignCenter>
            <Box size={50} stackGap={10}>
              <div className="badge -green -sm">NEW FEATURE</div>
              <div>
                <h3>Get paid faster with eInvoicing</h3>
                <p>
                  <Link to="/recruitment/features/einvoicing">eInvoicing</Link> is a new way
                  to send and receive invoices directly via a dedicated secure
                  global network. It means businesses can exchange invoices with
                  no emails, PDFs or manual handling. Send accurate, secure
                  invoices directly to your customers’ accounting software.
                </p>
              </div>
            </Box>
            <Box size={50}>
              <Image
                filename="InvoxyFeature_eInvoicing2.png"
                alt="Karmly eInvoicing"
                centerImage
                maxWidth={600}
              />
            </Box>
          </Row>
        </CountrySpecificContent>
        <Row stackGap={60} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_Invoicing_Builder.png"
              alt="Custom Invoice Builder"
            />
          </Box>
          <Box size={50}>
            <h3>Easy Template Builder</h3>
            <p>
              Create invoice templates for different clients, tailored to their
              exact requirements, with our highly configurable invoice builder.
              You have complete control over payment terms and the information
              that you want to display. Need a separate invoice for each
              contractor working for a client? Or a single consolidated invoice
              covering multiple contractors? No problem; we’ve got you sorted.
            </p>
          </Box>
        </Row>
        <Row stackGap={60} alignCenter>
          <Box size={50}>
            <h3>Volume Invoicing</h3>
            <p>
              Create dozens or hundreds of invoices in seconds. When you run an
              invoice batch, Karmly creates as many invoices as it needs to,
              using the templates you’ve saved. Say good riddance to manual
              invoicing.
            </p>
          </Box>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_Invoicing_CreateInvoice.png"
              alt="Bulk Invoicing"
            />
          </Box>
        </Row>
        <Row stackGap={60} alignCenter mobileReverse>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_Invoicing_Time.png"
              alt="Accurate Time Recording & Billing"
            />
          </Box>
          <Box size={50}>
            <h3>Always Accurate</h3>
            <p>
              Invoices are generated from approved timesheets so there’s no need
              for double entry or manual calculations. There’s no danger of
              undercharging clients as Karmly will notify you of any uninvoiced
              time from previous billing periods. Just as importantly it won’t
              let you accidentally invoice a client twice for the same work.
            </p>
          </Box>
        </Row>
        <Row stackGap={60} alignCenter>
          <Box size={50}>
            <h3>Faster payment with timesheet attachments</h3>
            <p>
              Ensure your invoices are paid on time and without dispute by
              attaching timesheet and approval records. Just choose whether to
              include them by default in your invoice template, or even attach
              timesheets from different sources if you have clients who aren’t
              able to use Karmly’s online timesheet and approval process.
            </p>
          </Box>
          <Box size={50}>
            <Image
              filename="InvoxyFeature_TimesheetManagement_AttachTimesheets.png"
              alt="Attach Timesheets to Invoices"
            />
          </Box>
        </Row>
      </Wrapper>
    </Container>
    <CallToAction />
  </Layout>
);

export default RecruitmentInvoicing;
